import Card from "../components/Card";
import Footer from "../components/footer";
import Header from "../components/Header/header";

import "./styles.css";
import PROJECTS from "../utils/projects";

const App = () => {
  return (
    <div className="container">
      <Header />
      <div className="cards-container">
        {PROJECTS.map(({ img, title, description, link, source }) => (
          <Card
            image={img}
            title={title}
            description={description}
            link={link}
            source={source}
          />
        ))}
      </div>
      <Footer />
    </div>
  );
};

export default App;
