import "./styles.css";

const Header = () => {
  return (
    <div className="brand">
      <h1 className="text-center">
        Luís <span>{"{"}</span> Lança <span>{"}"}</span>
      </h1>
      <h1 className="text-2xl mt-8 text-center">Portfolio</h1>
      <p className="font-normal mt-4 text-center">
        Here you will find some projects that I develop for my clients or some
        important studies that must be shared.
      </p>
    </div>
  );
};

export default Header;
