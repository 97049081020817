const PROJECTS = [
  {
    img: "assets/p1.png",
    title: "Personal blog",
    description:
      "Template for a personal blog developed with Gatsby.Js in order to study the framework. Not responsive",
    link: "https://gatsby-blog-tan-seven.vercel.app/",
    source: "https://github.com/lancaluis/gatsby-blog",
  },
  {
    img: "assets/p2.png",
    title: "Resume",
    description:
      "My personal website that I use as a resume. Developed with React.js, Typescript and Google Analytics",
    link: "https://luislanca.com",
    source: "https://github.com/lancaluis/resume",
  },
  {
    img: "assets/p3.png",
    title: "Spotify clone",
    description:
      "Project developed in HTML, CSS and JS for Erick Wendel's JS Expert course. The goal was to bring the concept of 'Pixel Perfect'",
    link: "https://spotify-clone-blush-rho.vercel.app/pages/radio.html",
    source: "https://github.com/lancaluis/spotify-clone",
  },
  {
    img: "assets/p4.png",
    title: "Love is in the air",
    description:
      "Just a joke between me and my girlfriend, programming is also fun :)",
    link: "https://projects-awvb.vercel.app/",
    source: "https://github.com/lancaluis/projects",
  },
  {
    img: "assets/p5.png",
    title: "196 countries",
    description:
      "Just one of my study projects in React.Js. Learning to consume an REST API. Not responsive",
    link: "https://countries-chi-inky.vercel.app/",
    source: "https://github.com/lancaluis/countries",
  },
];

export default PROJECTS;
